import React from "react"
import styled from "styled-components"

import SEO from "../../../components/shared/SEO"
import Layout from "../../../dsm/layout"
import Inner from "../../../dsm/layout/Inner"
import Card from "../card/LayoutA"
import PageHeader from "../../../layouts/PageHeader"
import CategoryNavigation from "../categories"
import getFlexiOptionsLayouts from "../../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import getFlexiOptionsContent from "../../../options/getFlexiOptionsContent"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const CategoriesArchive = ({ pageContext }) => {
  const { lightSkin } = getFlexiOptionsBranding()
  const { blogArchivePage } = getFlexiOptionsContent()

  const { name, posts, slug, description } = pageContext
  const { nodes } = posts

  // blog name
  const blogHeading =
    blogArchivePage.pageHeader ||
    process.env.GATSBY_BLOG_SLUG.charAt(0).toUpperCase() +
      process.env.GATSBY_BLOG_SLUG.slice(1)

  const {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  return (
    <Layout>
      <SEO
        title={`${blogHeading} | ${process.env.GATSBY_SITE_TITLE}`}
        description={blogArchivePage.introText || `${name} posts`}
        keywords={[name, `blog`]}
      />
      <PageHeader
        title={blogHeading}
        introText={blogArchivePage.introText}
        crumbs={[
          { pathname: "/", crumbLabel: "Home" },
          {
            pathname: `/${process.env.GATSBY_BLOG_SLUG}/`,
            crumbLabel: blogHeading,
          },
          {
            pathname: `/${process.env.GATSBY_BLOG_SLUG}/${slug}/`,
            crumbLabel: name,
          },
        ]}
      />
      <Wrapper lightSkin={lightSkin} mainBgColor={mainBgColor}>
        <Inner>
          <div>
            <h4>
              Our {process.env.GATSBY_BLOG_SLUG} in {name}
            </h4>
            <p dangerouslySetInnerHTML={{ __html: description }} />
            {nodes &&
              nodes.map((post) => <Card key={post.postId} post={post} />)}
          </div>
          <div className="sidebar">
            <div className="categories-widget">
              <h4>Select a Category</h4>
              <CategoryNavigation active={slug} />
            </div>
          </div>
        </Inner>
      </Wrapper>
    </Layout>
  )
}
export default CategoriesArchive

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? mainBgColor
      : lightSkin
      ? theme.colors.tusk[200]
      : theme.colors.midnight[500]};
  .categories-widget {
    position: sticky;
    top: 20px;
  }
  .inner {
    display: grid;
    grid-template-columns: 2fr 300px;
    grid-gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h4 {
    font-weight: 400;
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .inner {
      grid-template-columns: 1fr;
    }
  }
`
